<template>
  <base-section
    id="k-d-a-keep-in-touch"
    space="56"
    class="secondary"
  >
    <v-container>
      <v-row justify="space-between">
        <v-col
          cols="12"
          md="5"
        >
          <k-d-a-business-card-col
            :str-title="m_strBusinessCardTitle"
            :html-text="m_htmlBusinessCardText"
            str-text-color="black"
          />
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <k-d-a-contact-form :title="m_strContactFormTitle" />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  import BusinessDetails from '@/mixins/business-details'

  export default {
    name: 'KDAKeepInTouch',

    components: {
      KDABusinessCardCol: () => import('@/components/KDABusinessCardCol'),
      KDAContactForm: () => import('@/components/KDAContactForm'),
    },

    mixins: [BusinessDetails],

    data: () => ({
      m_strBusinessCardTitle: 'KEEP IN TOUCH WITH US',
      m_strContactFormTitle: 'MAIL US YOUR MESSAGE',
    }),

    computed: {
      m_htmlBusinessCardText () {
        return this.g_htmlBusinessDescriptionLong
      },
    },
  }
</script>
